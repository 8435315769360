import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router";
import TopBar from "../components/TopBar";

const CountryInfo = () => {

    const { country } = useParams();
    const [countryData, setCountryData] = useState();

    function fetchData() {
        fetch("https://restcountries.com/v3.1/name/" + country + "?fullText=true")
        .then(res => res.json())
        .then(json => {
            setCountryData(json[0]); 
            console.log("Data fetched");
        })
    }
    useEffect(() => {
        fetchData();
    }, [])
    return !!countryData ? (
        <>
            <TopBar/>
            <div className="country__content">
                <div className="return__btn">
                    <Link to={"/"}>
                        <button>{<FontAwesomeIcon className="back__arrow" icon="long-arrow-alt-left" />}Back</button>
                    </Link>
                </div>
                <div className="country__content--country">
                    <figure>
                        <img src={countryData.flags.png} alt=""/>
                    </figure>
                    <div className="country__description">
                        <h1 className="country__name">{countryData.name.official}</h1>
                        <div className="country__data">
                            {(typeof (countryData.name.nativeName) !== "undefined" && !!Object.values(countryData.name.nativeName)[0].official)  && 
                                <div className="country__data__pair">
                                    <h2 className="country__data__pair--title">Native Name:&nbsp;</h2>
                                    <p className="country__data__pair--value">{Object.values(countryData.name.nativeName)[0].official}</p>
                                </div>}
                            {(typeof (countryData.population) !== "undefined" && !!countryData.population) &&
                                <div className="country__data__pair">
                                    <h2 className="country__data__pair--title">Population:&nbsp;</h2>
                                    <p className="country__data__pair--value">{countryData.population}</p>
                                </div>
                            }
                            {(typeof (countryData.currencies) !== "undefined" && !!Object.values(countryData.currencies)[0].name) &&
                                <div className="country__data__pair">
                                    <h2 className="country__data__pair--title">Currency:&nbsp;</h2>
                                    <p className="country__data__pair--value">{Object.values(countryData.currencies)[0].name}</p>
                                </div>
                            }
                            {(typeof (countryData.capital) !== "undefined" && !!countryData.capital[0]) &&
                                <div className="country__data__pair">
                                    <h2 className="country__data__pair--title">Capital:&nbsp;</h2>
                                    <p className="country__data__pair--value">{countryData.capital[0]}</p>
                                </div>
                            }
                        </div>
                        {typeof (countryData.borders) !== "undefined" && 
                            <div className="country__borders">
                                <h2 className="country__borders--title">Border Countries:&nbsp;</h2>
                                <div className="country__borders--country-list">
                                    {countryData.borders.map((border) => {
                                        return <p className="country__borders--country" key={border} >{border}</p>
                                    })}
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
        </>
    ) : (
        <h1 className="loading">loading...</h1>
    )}
export default CountryInfo;