import React, { useState, useEffect } from "react";
import Country from '../components/Country';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const CountryList = () => {

    const [countries, setCountries] = useState();
    const [display, setDisplay] = useState();
    const [search, setSearch] = useState("");

    function fetchData() {
        fetch("https://restcountries.com/v3.1/all")
        .then(res => res.json())
        .then(json => {
            setCountries(json);
            setDisplay(json);
            console.log("Data fetched");
        })
    }

    useEffect(() => {
        fetchData();
    }, [])

    function filterRegion(filter) {
        if (filter === "all") {
            setDisplay(countries);
        } else {
            setDisplay(countries.filter(country => country.region === filter));
        }
    }

    function searchName() {
        setDisplay(countries.filter(country => country.name.official.toLowerCase().includes(search.toLowerCase()) || country.name.common.toLowerCase().includes(search.toLowerCase())))
    }

  return (
    <section className="contain">
        <div className="filter__contain">
            <div className="filter__search">
                <input className="filter__search__input filter__input" onKeyDown={(e) => e.key === "Enter" && searchName()} onChange={(e) => setSearch(e.target.value)} placeholder="search name here..." type="text"/>
                <button className="filter__search__btn" onClick={() => searchName()}><FontAwesomeIcon className="icons" icon="search"/></button>
            </div>
            <div className="filter__choice">
                <select className="filter__choice__input filter__input"
                    onChange={(event) => {
                        filterRegion(event.target.value);
                        event.target.value = "DEFAULT";
                    }}
                    defaultValue={"DEFAULT"} 
                >
                    <option value="DEFAULT" disabled> Sort </option>
                    <option value="Africa">Africa</option>
                    <option value="Europe">Europe</option>
                    <option value="Oceania">Oceania</option>
                    <option value="Asia">Asia</option>
                    <option value="Americas">Americas</option>
                    <option value="Antarctic">Antarctic</option>
                    <option value="all">All Regions</option>
                </select>
            </div>
        </div>
        <div className="transport" onClick={() => window.scrollTo(0, 0)}>
            {<FontAwesomeIcon className="icons" icon="arrow-up" />}
        </div>
        <div className="countries">
            {!!display ? display.map((country) => {
                  return <Country country={country} key={country.name.official}/>;
            }) : (
                <h1 className="loading">loading...</h1>
            )}
        </div>
    </section>
  );
}

export default CountryList;
