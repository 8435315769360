import React from "react";
import TopBar from "../components/TopBar";
import CountryList from "../components/CountryList";


const Home = () => {
    return (
        <>
            <TopBar />
            <CountryList />
        </>
    );
};

export default Home;