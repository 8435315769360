import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import CountryInfo from "./pages/CountryInfo";

function App() {

  return (
    <BrowserRouter>
        <Routes>
          <Route path="/" exact element={<Home/>}/>
          <Route path="/:country" element={<CountryInfo/>}/>
        </Routes>
    </BrowserRouter>
  );
}

export default App;
