import React from "react";
import { Link } from "react-router-dom";

const Country = ({ country }) => {

    return (
        <Link to={`/${country.name.official}`}>
            <div className="country">
                <figure className="flag">
                    <img src={country.flags.png} alt="country flag" className="flag__img"/>
                </figure>
                <div className="information">
                    <h1 className="name">{country.name.official}</h1>
                    <div className="stats">
                        <div className="stat">
                            <h2 className="subheading">Population:&nbsp;</h2>
                            <p className="stat__value">{country.population}</p>
                        </div>
                        <div className="stat">
                            <h2 className="subheading">Region:&nbsp;</h2>
                            <p className="stat__value">{country.region}</p>
                        </div>
                        {(typeof (country.capital) !== "undefined" && !!country.capital[0]) && 
                        <div className="stat">
                            <h2 className="subheading">Capital:&nbsp;</h2>
                            <p className="stat__value">{country.capital[0]}</p>
                        </div>}
                    </div>
                </div>
            </div>
        </Link>
    );
    };
  export default Country;
  