import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

function TopBar() {
  return (
    <section className="header">
        <Link to={"/"}>
          <div className="header__title">
              <p className="header__title--text">Where in the world?</p>
          </div>
        </Link>
        <button href="#" className="header__theme--btn" onClick={() => {
          document.body.classList.contains("theme--dark") 
          ? 
          document.body.classList.remove("theme--dark")
          :
          document.body.classList.add("theme--dark")
          }}>
          <div className="header__theme">
            <FontAwesomeIcon className="header__theme--icon icons" icon="moon"/>
            <p className="header__theme--text">Dark Mode</p>
          </div>
        </button>
    </section>
  );
}

export default TopBar;
